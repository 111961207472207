<template>
  <div>
    
      <van-row type=:flex justify="center">
        <van-col span="4"></van-col>
        <van-col span="16">
          <van-tag type="primary" class="top-tag">快速检查</van-tag>
        </van-col>        
        <van-col span="4"></van-col>
      </van-row>      

      <van-divider dashed />

      <van-row type="flex" class="each-row">
        <van-col span="1"></van-col>
        <van-col span="11">
          <van-tag type="primary" class="verify-tag"> 取号成功数： <span> {{ cnt_picked }} </span> </van-tag> 
        </van-col>
      </van-row>    

      <van-row type="flex" class="each-row">
        <van-col span="1"></van-col>
        <van-col span="11">
          <van-tag type="primary" class="verify-tag"> 取号失败数： <span> {{ cnt_failed }} </span> </van-tag> 
        </van-col>
      </van-row> 

      <van-divider dashed />

      <van-row type="flex" class="each-row">
         <van-col span="9"></van-col>
        <van-col span="3">
          <van-button plain type="primary"
            size="small"
            @click="reflash()"
            >刷新</van-button>
        </van-col>
        
      </van-row>

  </div>
</template>

<script>
import axios from 'axios';
import {Button} from 'vant'
import { Col, Row, Tag,  Divider  } from 'vant';

export default {
  inject:['reload'],
  components: {
    vanButton:Button,
    vanCol:Col,
    vanRow:Row,
    vanTag:Tag,
    vanDivider:Divider 
  },

  data() {
    return {
      cnt_picked:-1,
      cnt_failed:-1,
    };
  },

  computed: {
  },


  mounted(){
  },

  methods: {

    reflash(){
      this.reload()
    },
    
  },
  created(){

    (function () {
      var viewportTag = null;
      var metaTags = document.getElementsByTagName('meta');
      for (var i = 0; i < metaTags.length; i++) {
        if (metaTags[i].getAttribute('name') === "viewport") {
          viewportTag = metaTags[i];
          break;
        }
      }
      if (!viewportTag) {
        viewportTag = document.createElement("meta");
        viewportTag.setAttribute('name', 'viewport');
      }

      var viewportTagContent = 'width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=0';

      // Detect if iOS device
      if (/(iPhone|iPod|iPad)/i.test(window.navigator.userAgent)) {
        // Get iOS major version
        var iosVersion = parseInt((window.navigator.userAgent).match(/OS (\d+)_(\d+)_?(\d+)? like Mac OS X/i)[1]);
        // Detect if device is running >iOS 11
        // iOS 11's UIWebView and WKWebView changes the viewport behaviour to render viewport without the status bar. Need to override with "viewport-fit: cover" to include the status bar.
        if (iosVersion >= 11) {
          viewportTagContent += ', viewport-fit=cover';
        }
      }

      // Update viewport tag attribute
      viewportTag.setAttribute('content', viewportTagContent);
    })()

    const biz = this.$route.params.biz;
    const shop = this.$route.params.shop;
    const token = this.$route.params.token;
    if(biz && shop && token){
      this.token = token
      // 发ajax请求，用以获取数据，此处地址意思是查询 github中 vue 星数最高的项目
      const url = '/tokenlookup/'+ biz +'/' + shop + '/' + token;    
      axios.get(url).then(
          res => {
            let result = res.data.data
            this.cnt_picked = result.cnt_picked;
            this.cnt_failed = result.cnt_failed;
          }
      ).catch(
          rsp => {
            window.console.log(rsp);          
          },
      );
    }else{
      window.console.log('noe allow');
    }
  },
  beforeDestroy() {
  }
};
</script>

<style scoped>
.body {
  font-size: 0.3rem;
}
.each-row{
  font-size: 1px;;
}
.top-tag {
  font-size: 0.6rem;
  line-height: 0.8rem;
}

.sub-tag {
  font-size: 0.4rem;
  line-height: 0.6rem;
  color:black;
  background-color:yellow;
}

.red-w-tag {
  font-size: 0.4rem;
  line-height: 0.6rem;
  color:crimson;
  background-color:white
}

.red-tag {
  font-size: 0.4rem;
  line-height: 0.6rem;
  color:crimson;
  background-color:#DFE0E7;
}

.blue-tag {
  font-size: 0.4rem;
  line-height: 0.6rem;
  color:blue;
  background-color:white;
  margin-bottom: 10px;
}

.phone-tag {
  font-size: 0.55rem;
  line-height: 0.8rem;
  color:black;
  background-color:white;
  margin-top: 2px;
  margin-bottom: 3px;
}

.verify-tag {
  font-size: 0.55rem;
  line-height: 0.8rem;
  color:black;
  background-color:white;
  margin-top: 2px;
  margin-bottom: 3px;
}
.note-row{
  font-size: 0.35rem;
  color:gray;
  margin-bottom: 5px;
}
.dialog-button-bg {
  background: white;
  text-align: center;
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
}
</style>